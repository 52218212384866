import React from "react";
import Ribbon from "../components/templates/ribbon";
import Panel from "../components/templates/panel";
import Content from "../components/templates/content";
import Proposal from "../components/templates/proposal";
import Layout from "../components/layout";
import SEO from "../components/seo";

import Login from "../components/login";

const AboutPage = () => (
  <Layout>
    <Login />
    <SEO title="About Us" />
    <Panel imgLink={"/images/content-img-about-header.jpg"}>
      <div className="purpose mobile-hide">
        <h1>
          What sets us apart is what makes us the ideal partner for Downtown
          West — we take projects from purpose to production with award-winning
          creativity.
        </h1>
      </div>
    </Panel>
    <div className="mobile-show desktop-hide">
      <Ribbon bgColor="black">
        <h2>
          What sets us apart is what makes us the ideal partner for Downtown
          West — we take projects from purpose to production with award-winning
          creativity.
        </h2>
      </Ribbon>
    </div>

    <Content>
      <div className="container">
        <h2 className="compress">
          We understand the opportunities and challenges of remarkable projects
          like Downtown West, from needing to build genuine connections with
          real people to the complexities of multi-stakeholder engagement and
          defined budgets. Our senior creative and management team has wide
          experience working on projects such as this.
        </h2>

        <br></br>
        <br></br>
      </div>
      <img src="/images/Capabilities diagram_v4.jpg" width="100%" />

      <div className="container">
        <p className="caption">
          A rare combination of industry insight, brand strategy, writing,
          design and digital capabilities.
        </p>
        <div style={{ padding: "40px" }}></div>
        <div className="row">
          <div className="col-md-4 strip">
            <div>
              <div>30 partners working across:</div>
              <div>&mdash; Brand strategy &amp; purpose</div>
              <div>&mdash; Visual identity</div>
              <div>&mdash; Design &amp; art direction</div>
              <div>&mdash; Digital</div>
              <div>&mdash; Editorial</div>
              <div>&mdash; Advertising</div>
              <div>&mdash; Marketing</div>
              <div>&mdash; Film</div>
              <div>&mdash; Social media</div>
              <div>&mdash; Environmental</div>
              <div>&mdash; Signage &amp; wayfinding</div>
            </div>
          </div>
          <div className="col-md-4 strip">
            <div>
              We have a sister company called Place Press: a publisher exploring
              the intersection between people, places and culture.
            </div>
          </div>
          <div className="col-md-4 strip">
            <div>
              We’re also a tech company, Showhere: a specialist consultancy and
              website service for the real estate industry.
            </div>
          </div>
        </div>
        <div style={{ padding: "40px" }}></div>
      </div>
      <div className="ribbonBkJoy">
        <Ribbon white={false}>
          <h2>
            We were founded 14 years ago by Joy Nazzari, a Brazilian-American
            passionate about place branding. We coined Place Purpose and wrote a
            book about it.
          </h2>
          <p>
            Joy at TEDx: ‘The Power in Cities — turning spaces into
            places’,&nbsp;
            <a href="https://www.youtube.com/watch?v=LPYNGi2AA3Q&t=1s">
              watch it on YouTube
            </a>
          </p>
        </Ribbon>
      </div>

      <div className="container">
        <div className="breaker"></div>
        <div className="heading-block">
          <h2>
            We’re experienced in complex, multi-stakeholder, place branding
            projects
          </h2>
          <br></br>
          <h3 className="lower-heading">
            — Royal Docks: 1,200 acres<br></br>— Brent Cross Town: 180 acres
            <br></br>— St James’s: 100 acres<br></br>— Digbeth: 70 acres
            <br></br>— Pier 70: 35 acres<br></br>— Broadgate: 32 acres<br></br>
          </h3>
        </div>
      </div>
      <div className="container">
        <div className="breaker"></div>
        <h2 className="compress">
          Trusted internationally by developers and local government
        </h2>
        <br></br>
      </div>
      <div className="grey">
        <Ribbon>
          <div className="container">
            <br></br>
            <br></br>
            <div className="row box">
              <div className="col-md-3 col-sm-6">
                <img src="/images/about-logos/1.png" />
              </div>
              <div className="col-md-3 col-sm-6">
                <img src="/images/about-logos/2.png" />
              </div>
              <div className="col-md-3 col-sm-6">
                <img src="/images/about-logos/3.png" />
              </div>
              <div className="col-md-3 col-sm-6">
                <img src="/images/about-logos/4.png" />
              </div>
            </div>
            <br className="mobile-hide"></br> <br className="mobile-hide"></br>
            <div className="row box">
              <div className="col-md-3 col-sm-6">
                <img src="/images/about-logos/5.png" />
              </div>
              <div className="col-md-3 col-sm-6">
                <img src="/images/about-logos/6.png" />
              </div>
              <div className="col-md-3 col-sm-6">
                <img src="/images/about-logos/7.png" />
              </div>
              <div className="col-md-3 col-sm-6">
                <img src="/images/about-logos/8.png" />
              </div>
            </div>
            <br className="mobile-hide"></br> <br className="mobile-hide"></br>
            <div className="row box">
              <div className="col-md-4 col-sm-6">
                <img src="/images/about-logos/9.png" />
              </div>
              <div className="col-md-4 col-sm-6">
                <img src="/images/about-logos/10.png" />
              </div>
              <div className="col-md-4 col-sm-6">
                <img src="/images/about-logos/11.png" />
              </div>
              <div className="col-sm-6 desktop-hide">
                <img src="/images/about-logos/12.png" />
              </div>
            </div>
            <br className="mobile-hide"></br> <br className="mobile-hide"></br>
            <div className="row box">
              <div className="col-md-3 col-sm-6 mobile-hide">
                <img src="/images/about-logos/12.png" />
              </div>
              <div className="col-md-3 col-sm-6">
                <img src="/images/about-logos/13.png" />
              </div>
              <div className="col-md-3 col-sm-6">
                <img src="/images/about-logos/14.png" />
              </div>
              <div className="col-md-3 col-sm-6">
                <img src="/images/about-logos/15.png" />
              </div>
              <div className=" col-sm-6 desktop-hide">
                <img src="/images/about-logos/16.png" />
              </div>
            </div>
            <br className="mobile-hide"></br> <br className="mobile-hide"></br>
            <div className="row box">
              <div className="col-md-3 col-sm-6 mobile-hide">
                <img src="/images/about-logos/16.png" />
              </div>
              <div className="col-md-3 col-sm-6">
                <img src="/images/about-logos/17.png" />
              </div>
              <div className="col-md-3 col-sm-6">
                <img src="/images/about-logos/18.png" />
              </div>
              <div className="col-md-3 col-sm-12">
                <img src="/images/about-logos/19.png" />
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
        </Ribbon>
      </div>

      <div className="container">
        <div className="breaker"></div>
        <br></br>
        <h2>Our proven process:</h2>
        <br></br>
        <br></br>
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-4 col-sm-6 process">
            <div className="process-box">
              <p>
                <span>
                  1.<br></br> Client workshop, establish business objectives
                  &amp; KPIs
                </span>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 process">
            <div className="process-box ">
              <p>
                <span>
                  2.<br></br> Audience analysis, market research, competitor
                  analysis, interviews
                </span>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 process">
            <div className="process-box">
              <p>
                <span>
                  3.<br></br> Establish purpose and positioning
                </span>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 process">
            <div className="process-box ">
              <p>
                <span>
                  4.<br></br> Develop brand identity
                </span>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 process">
            <div className="process-box">
              <p>
                <span>
                  5.<br></br>Go to market plan
                </span>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 process">
            <div className="process-box ">
              <p>
                <span>
                  6.<br></br>Implement brand, team on-boarding, brand guidelines
                </span>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 process">
            <div className="process-box">
              <p>
                <span>
                  7.<br></br>Launch
                </span>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 process">
            <div className="process-box ">
              <p>
                <span>
                  8.<br></br>Measure, monitor, fine-tune
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="breaker"></div>
        <div className="heading-block">
          <h2>Your team: who’s who</h2>
        </div>
        <div className="breaker-thin"></div>
        <div className="team-block">
          <div className="row">
            <div className="col-4">
              <img src="/images/content-img-about-3.png" width="100%" />
            </div>
            <div className="col-8">
              <h2>
                Joy Nazzari<br></br>Head of Business
              </h2>
              <br></br>
              <p>
                Joy started her career in technology equity research until she
                dove into the Silicon Valley dotcom boom — she spent a year
                commuting down 280 to Sunnyvale and understands the Bay Area
                dynamics first hand. Her career quickly transitioned into
                software and branding for places, where she has been advising
                clients for nearly 20 years on the importance of relating to
                people in order to make places successful.
                <br></br>
                <br></br>
                Joy was an immigrant to the US, has lived in three continents
                and speaks 4 languages, a true San Francisco story.
                <br></br>
                <br></br>
                Joy’s focus is on commercial success of each project, ensuring
                that brand and execution are relevant and true to product,
                target audience and objectives. Clients benefit from her
                business-focused perspective, which complements the team’s
                creative ability.
                <br></br>
                <br></br>— Joy is fluent in 4 languages<br></br>— BA (Hons)
                Economics, University of California at Santa Barbara
              </p>
            </div>
          </div>
        </div>
        <div className="breaker"></div>
        <div className="team-block">
          <div className="row">
            <div className="col-8 order-12 order-sm-1">
              <h2>
                Patrick Eley<br></br>Creative Director
              </h2>
              <br></br>
              <p>
                With over 20 years in the industry, Patrick has broad experience
                across all disciplines of branding and design – from static
                print and moving image to wayfinding and immersive environments.
                <br></br>
                <br></br>
                Patrick started his career in design at the ever-pioneering
                National Theatre, subsequently spending seven years at cultural
                design heavyweight, Spin. His work in the cultural field is
                extensive and he has worked with brands across the UK and the
                world including; Channel 4, MTV, Royal Court Theatre,
                Whitechapel Gallery, Royal Academy and Deutsche Bank.
                <br></br>
                <br></br>
                Patrick leads the creative direction of all projects at dn&co
                pushing innovation across the studio and ensuring outputs are
                impactful and nuanced and truly reflect the ambitions of our
                clients.
                <br></br>
                <br></br>— MA Typo/Graphic Studies, University of the Arts
                London
              </p>
            </div>
            <div className="col-4 order-1 order-sm-12">
              <img src="/images/content-img-about-4.png" width="100%" />
            </div>
          </div>
        </div>
        <div className="breaker"></div>
        <div className="team-block">
          <div className="row">
            <div className="col-4">
              <img src="/images/content-img-about-5.png" width="100%" />
            </div>
            <div className="col-8">
              <h2>
                Simon Yewdall<br></br>Strategy Director
              </h2>
              <br></br>
              <p>
                Simon comes from a background in advertising, working at the
                UK’s biggest agency, AMV BBDO, on brands such as Guinness,
                Clarks, Aviva, and the V&A.
                <br></br>
                <br></br>
                He has a keen sense for how brands and places communicate and
                has shaped the tone of voice and brand strategy for places
                including Broadgate, the Royal Docks, Pier 70 and St James’s,
                where he was also the editor in chief of its newspaper, The
                Correspondent.
                <br></br>
                <br></br>
                Simon’s role is to undercover the context in which brands and
                places compete, advance the strategy and compile a compelling
                narrative. He is co-author with Joy Nazzari of place branding
                book, Know Your Place.
                <br></br>
                <br></br>— BA (Hons) Illustration, University of Loughborough
                <br></br>— BA (Hons) Geography, University of Durham
              </p>
            </div>
          </div>
        </div>
        <div className="breaker"></div>
        <div className="team-block">
          <div className="row">
            <div className="col-8 order-12 order-sm-1">
              <h2>
                Luis Mendoza<br></br>US Lead
              </h2>
              <br></br>
              <p>
                Luis comes from an editorial and commercial background in
                television and print including Monocle magazine, the lifestyle
                media brand on cities and good design. He led branded content
                projects for the likes of Nike, BMW, The Peninsula Hotels or
                Tourism of Japan.
                <br></br>
                <br></br>
                At dn&co he works across projects with a multidisciplinary
                approach including research, brand strategy, content and
                creative strategy and business development.
                <br></br>
                <br></br>
                Luis leads dn&co’s US projects and has a deep understanding of
                the Bay Area’s idiosyncrasies through his work on large-scale
                projects such as Pier 70. He has undertaken extensive community
                engagement and established ties with key members of the
                community and organisations in the area.
                <br></br>
                <br></br>— Bilingual in English and Spanish
                <br></br>— LLB Law,
                <br></br>— BA (Hons) Journalism,
                <br></br>— BA (Hons) Media studies, Carlos III University of
                Madrid
              </p>
            </div>
            <div className="col-4 order-1 order-sm-12">
              <img src="/images/content-img-about-6.png" width="100%" />
            </div>
          </div>
        </div>
        <div className="breaker"></div>
        <div className="team-block">
          <div className="row">
            <div className="col-4">
              <img src="/images/content-img-about-7.png" width="100%" />
            </div>
            <div className="col-8">
              <h2>
                Zoë Barrett<br></br>Account Director
              </h2>
              <br></br>
              <p>
                Zoë has extensive experience managing large-scale
                multi-stakeholder projects. Over the last five years at dn&co,
                Zoë she has managed Television Centre’s £10 million marketing
                budget, helped launch the brand for Birmingham’s creative
                neighbourhood Digbeth, and delivered the Museum of the Home’s
                repositioning, rebrand and wayfinding as part of a £18.1 million
                capital project.  <br></br>  <br></br>
                She has recently overseen the wayfinding strategy and design for
                the V&A’s seven miles of galleries in South Kensington.  
                <br></br>  <br></br>
                Zoë acts as the day-to-day contact for clients across all
                aspects of a project. She will manage design and production
                schedules while keeping within the budget.  <br></br>  <br></br>
                — BA (Hons) Graphic Design, University of Kingston
              </p>
            </div>
          </div>
        </div>
        <div className="breaker"></div>
        <div className="team-block">
          <div className="row">
            <div className="col-8 order-12 order-sm-1">
              <h2>
                Sam Jones
                <br></br>Design Director
              </h2>
              <br></br>
              <p>
                With 10 years experience, Sam has worked with brands in a
                variety of sectors – ranging from an extensive rebrand of
                outdoor clothing company, Regatta, to developing an identity for
                Monotype, the world’s largest font technology company. At dn&co,
                he has led several large-scale place identity projects for
                Broadgate and Digbeth, and, more recently, created the new
                identity for the Museum of the Home based in East London.  His
                work had been recognised by Creative Review, Eye and the
                International Society of Typography Designers, in which his work
                was awarded 2 ISTD Premier awards.
                <br></br>
                <br></br>
                Sam runs the design team day-to-day and ensures the creative
                vision is fully visualised.
                <br></br>
                <br></br>— BA (Hons) Graphic Design, Cambridge School of Art
                <br></br>— MA Graphic Design, University of the Arts London
              </p>
            </div>
            <div className="col-4 order-1 order-sm-12">
              <img src="/images/content-img-about-8.png" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </Content>
    <Proposal
      fwd={true}
      bck={true}
      linkBack={""}
      titleBack={"Introduction"}
      linkTo={"purpose"}
      titleTo={"Place Purpose"}
    />
  </Layout>
);

export default AboutPage;
